import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Gallery from '../../components/gallery.jsx';
import Embed from '../../components/embed.jsx';
export const _frontmatter = {
  "key": 0,
  "title": "Richard Hotline",
  "type": "Digital Installation",
  "date": "2019",
  "description": "This is a project born out of frustration to the expectation of immediate reaction of instant messaging which results in always having to be connected. It is like a system that I have to conform myself to, but what if that system can conform to me? I wanted to find to make the digital world come to me instead of me having to seek the digital. Also, making people jump through this hoop makes the messages feel more personal",
  "image": "../../static/img/work/richardhotline/richardhotline-12.png",
  "alt": "test image",
  "length": null,
  "website": "https://richardhotline.nl",
  "repo": "https://github.com/RichART-Official/richardhotline",
  "rating": 5
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Gallery width={50} mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "3532px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f416cd68499a369dee53eaad952e18b3/1e955/compose-window.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "64.4960362400906%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAADhElEQVQ4yz2Sa0xbZRjHD1DbUgqFsnYwCky/zETnFy/JPhmTia23GKMwYJP1enoBjBMH3r65Sy9RPNCeXtdCgamTCTrZNGiYQxHHB53GbIsuomYXIuripHBOd/4+pzP98Mv7Pv/zvE/e8/7/THAytiN8ejIam58ZDM9NceG5aS5y+kMu9fUnXObsLDey9BmXXDjJxb+c4dJUy1p68dNCn8wwnYnMTQ/G5z9OBE+MP8nog129psl+bJ3oQ+PEi9g89gKMRNPRPtz+7n40v/MS6sb3FZD3stZI3+SeuvFbfc1j+2CaegW1b9nDTMmhVpch7UZ9il2v4m2CIrxXUNJqyviEhiNeoYq3CyVDXUJ5xCY0Ul2XdAsVtJc1XcwhNKTcwqa4M6dM2MH42/xMRaCD3TLaDWrMV/MOiTnwNJg3noL28DNQ+5+FYqgDzOCuwqrjn4N6eDcUHGlvtqKU60RDygN9zClWZjxQBjuDjOpwO9uQ7YExweaNcZdk/YBDYP59BM68RxxDaGGySPB/bu2Po//zLJqTXtTSQEO2G6pgZ4gpOdjGGkd82EwDa+iG2aVZ/HzlN1y4uoyLV3/FxSvLuHD5F2K5yHmqL137HWd+Ooc7kj5URx1i9YgXikBHiGEOtrLqpEu+el4fdUqvn5rA8bNfYOrbrwpMf7eAEz98U+SjAos49eMSEouzMMW9dBmXqE6zKA20h5iyQ7vY0rgDFbwtrwnbpOlzi1i7cQPX/lrFyp+r+OP63/hnPUesFbme+xcbooDzK5dRE2ah4a3ibSkXDeyQB7ax+rQHhoQrr4s4pGPfL2BN3ECODmyIYoF1QSiQEzaKq3RTwqXVFTTFu1Eds4saSooiSAPV/na2kUyhSIg63i71zYxIAyez0n2jA9L92Zel7en90p1H+qR7Mv3SjvFXpXtJv5u0baTdRWxN+SQyU9DQL9MbBplyik09OWQgU8h+6YGx17A9MwA9PYMuZoc2akPJ8B4oI3thSDpRSXUFbwUztKfwrT7lpl6nWDPqgypELisOtLJbMuRy1HnTwDtQ9vZuyl0nxYGegXfKOjRDVmiHbWgibRP1GElTcV2gVMCUcKM2Ys/Xyjn0kymM9UFTVY/5cX2vxazxtli0PrNF6Wmx6Hsfteh6zBZZU3h2WsrcOy2G5x+zVHY/UtDkWuV9uKBpfS3mcl/LE6X2h7b9B5rhWEgKjFPbAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/f416cd68499a369dee53eaad952e18b3/3cea3/compose-window.webp 1000w", "/static/f416cd68499a369dee53eaad952e18b3/b7abf/compose-window.webp 2000w", "/static/f416cd68499a369dee53eaad952e18b3/a77c0/compose-window.webp 3532w"],
                "sizes": "(max-width: 3532px) 100vw, 3532px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/f416cd68499a369dee53eaad952e18b3/e11df/compose-window.png 1000w", "/static/f416cd68499a369dee53eaad952e18b3/d40c8/compose-window.png 2000w", "/static/f416cd68499a369dee53eaad952e18b3/1e955/compose-window.png 3532w"],
                "sizes": "(max-width: 3532px) 100vw, 3532px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/f416cd68499a369dee53eaad952e18b3/1e955/compose-window.png",
                "alt": "compose window",
                "title": "compose window",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "3532px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/303af0ee0dcde441d87adb9ff94e8c8f/1e955/end-screen.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "64.4960362400906%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAACcklEQVQ4y42SX0hTURzHzzWolyyZbUYLIrCCoD+YDwayUqI2mOJLQuBDFNSbYvUSQWDE1GZkN7alG8IEyTYFYVtpiAUlpZCCgRgI/tmG3C2nyVB3tnu/nfuHtiJHDx/O7/c7v9+Hc+495Mnk+Hnn0vzL7ki007GwwDsWF3kno2dlhfcKAu+NxXhPNMq7w2El9sbjfA+ry305dHZFIm77zEwtKYpGGw8AKGboNORYrukZBm3V5+SGv/p1Wn9RPO4gJBa7RVjC2GZQhc1NSjZ+UraXRRAoWf1BiZihv/uybCmOtbV2wgnCbU2YKRAlSY5NwiruDoTQHBhF49AIWr/Oom8jiQej47BNzWFgfQsNaZHNSCiQJBBJSstzXCJhJyRXmBEVofXLFNw3m+BqegjXncfoffQCXffb8NkziNDTHmy/n8az1aQ884eQJBId/xRWf5pE2/VG1FbVoar8Eoy6oyg7Vo4PvhEMdvvwhknvzS0pQi6/MKMIL3ycQM2pSpw7WYEjJaUw7DOipPAQ6q9cxbvXw/C18Kh+NaQKRXFnIacJK0fGcHGvEdYzJrx19sHX6oLf5oL5eDnqzppgszagtKVdEZJMHiER1SsXL0ew39MLvceLyxPTuDa/jPrviygbHsOe5w7oXW4Yvs2qwjxXTrMNWSgdpFSqoCklZs9EIttbEklTNc9F7lehmtAuv8PsldVGnE4mcSMcBpdOg9WwS0P5AeyK2neDNqceRl7X19kJIxFVmEqJhFJwDBaryPH/kEplFEcs1kG4/v7DXDBo5QIBMxcIWkggYNkdDFoKQyELq7E8qKw7o+ybuVCohvP7T/wCiWDCU2Xl7LsAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/303af0ee0dcde441d87adb9ff94e8c8f/3cea3/end-screen.webp 1000w", "/static/303af0ee0dcde441d87adb9ff94e8c8f/b7abf/end-screen.webp 2000w", "/static/303af0ee0dcde441d87adb9ff94e8c8f/a77c0/end-screen.webp 3532w"],
                "sizes": "(max-width: 3532px) 100vw, 3532px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/303af0ee0dcde441d87adb9ff94e8c8f/e11df/end-screen.png 1000w", "/static/303af0ee0dcde441d87adb9ff94e8c8f/d40c8/end-screen.png 2000w", "/static/303af0ee0dcde441d87adb9ff94e8c8f/1e955/end-screen.png 3532w"],
                "sizes": "(max-width: 3532px) 100vw, 3532px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/303af0ee0dcde441d87adb9ff94e8c8f/1e955/end-screen.png",
                "alt": "visual confirmation window",
                "title": "visual confirmation window",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    </Gallery>
    <Gallery mdxType="Gallery">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "4000px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/dc7a58a35e6956d03f51cc292b53aa23/33840/richardhotline-3.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAgb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAFtTNSPBMH/xAAZEAADAQEBAAAAAAAAAAAAAAABAgMAEhP/2gAIAQEAAQUCLqgNlG95atGpat3ZeQN//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgEEAwAAAAAAAAAAAAAAAAERAhAxQSEiMv/aAAgBAQAGPwLs4Kp1k9jb1gdLjm3/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhUTFx/9oACAEBAAE/ISCNsLh2oG1yL7Q+xGKtjlTBAi0PYAqf/9oADAMBAAIAAwAAABCID//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/EFf/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhMXFh/9oACAEBAAE/EBxa0dfkbMGBfQB62Sj1i6CJ7k1co6AXIgAWIGnLhGl+z//Z')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/dc7a58a35e6956d03f51cc292b53aa23/3cea3/richardhotline-3.webp 1000w", "/static/dc7a58a35e6956d03f51cc292b53aa23/b7abf/richardhotline-3.webp 2000w", "/static/dc7a58a35e6956d03f51cc292b53aa23/cc8bc/richardhotline-3.webp 4000w", "/static/dc7a58a35e6956d03f51cc292b53aa23/d50d9/richardhotline-3.webp 5184w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/dc7a58a35e6956d03f51cc292b53aa23/971c4/richardhotline-3.jpg 1000w", "/static/dc7a58a35e6956d03f51cc292b53aa23/4aca8/richardhotline-3.jpg 2000w", "/static/dc7a58a35e6956d03f51cc292b53aa23/cc43b/richardhotline-3.jpg 4000w", "/static/dc7a58a35e6956d03f51cc292b53aa23/33840/richardhotline-3.jpg 5184w"],
                "sizes": "(max-width: 4000px) 100vw, 4000px",
                "type": "image/jpeg"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/dc7a58a35e6956d03f51cc292b53aa23/cc43b/richardhotline-3.jpg",
                "alt": "compose window",
                "title": "compose window",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "2032px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c868a26f811ea02cfc57d2ba03e05998/f4ad5/code.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": ["noopener"]
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "57.52952755905512%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAACQElEQVQozz1TSY7UQBD0R2bxUvvqsrsbNBL8A0sIiT4gngS+cOCNaOSxk8jqmTmEujqdmRURmdWYIP/et3e/pB7WlP2akl3b/mHtRbsap1bn9TrNcR3HgG8OMYmYXEPQ62N3/4bfwJ+H9u6p+fh0phgUeSAlQ2UK5HBGUY3l0VEaLU2TpxA5Ll4hydgB6Ov/jJy2u/vZ5OzIOfVPm2GzbthcGDalu02qdhPycbNeIS62UlxFCLICTTYPcI338rkUS/3w8L0pcyJtJDc4YtI0nx3FJEnblqzv69mCSfKGzudcmfggSekW6IjrjOn3hFqj+2sTIRN+oWF3sNQ8GfLxJosLQoR0yEvZ0HTSkK4pBAESPTcjgYY470xmEI/XxliJw62hsQJFnuaToBleskfckP0qiJ8vnnISFOElA3L54gOM9wASXX9/bXKJkOU23HJwIUvSMFrhdqU6DEiQQWF2YJkULunArnuXDIDITTI8vDY+oYERlWGAX/PFvcthcBGGQrkYMOdp8xAFvnVvOYfW3c6XVYbYNcL+1Ibw7shFHtb1B98MFkc13Q5HyeEYS+DBHVgf9u01p+KFSQwSHsaoeSjPQrYvynT7OIc9ZbVjZd4Bhvs0hb1Mene2Q6zdYckNaMYrxmwrw9NlhPEW0NW/8yXRiJ3yMJ0X+W25Pz6d6NPnmT5gMPwAOJ8ne8uRxNsClT/QcPqGp/UFWOZTXsbiAL9MU8RvWPLo639nxQIWCx7AgmaIIw/f8DBqHuq/Ykv6/ySfSUsVwbMxAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/c868a26f811ea02cfc57d2ba03e05998/3cea3/code.webp 1000w", "/static/c868a26f811ea02cfc57d2ba03e05998/b7abf/code.webp 2000w", "/static/c868a26f811ea02cfc57d2ba03e05998/dbad2/code.webp 2032w"],
                "sizes": "(max-width: 2032px) 100vw, 2032px",
                "type": "image/webp"
              }}></source>{`
        `}<source parentName="picture" {...{
                "srcSet": ["/static/c868a26f811ea02cfc57d2ba03e05998/e11df/code.png 1000w", "/static/c868a26f811ea02cfc57d2ba03e05998/d40c8/code.png 2000w", "/static/c868a26f811ea02cfc57d2ba03e05998/f4ad5/code.png 2032w"],
                "sizes": "(max-width: 2032px) 100vw, 2032px",
                "type": "image/png"
              }}></source>{`
        `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/c868a26f811ea02cfc57d2ba03e05998/f4ad5/code.png",
                "alt": "visual confirmation window",
                "title": "visual confirmation window",
                "loading": "lazy"
              }}></img>{`
      `}</picture>{`
  `}</a>{`
    `}</span></p>
    </Gallery>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      